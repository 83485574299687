<template>
  <div
    class="cta-stoerer"
    :style="{
      '--cta-background-color': data.color?.hex,
      '--cta-background-color-hover': hoverColor,
    }"
  >
    <Link v-if="data.link" :link="data.link" class="text-lg-stoerer link">
      <SvgLinkExternal />
    </Link>
  </div>
</template>

<script setup lang="ts">
import type { CtaStoererFragment } from '#gql';
import Link from '~/components/content-modules/components/Link.vue';
import SvgLinkExternal from '~/components/content-modules/svgs/SvgLinkExternal.vue';

const props = defineProps<{
  data: CtaStoererFragment;
}>();

const hoverColor = computed(function () {
  const color = props.data.color?.hex?.toLowerCase();

  if (color === '#c94c32') {
    return '#BD3D24'; // hover for aprico-dark
  } else if (color === '#9b7aed') {
    return '#8E58FF'; // hover for purple
  } else if (color === '#547203') {
    return '#46590d'; // hover for meadow-dark
  } else {
    return color; // default color
  }
});
</script>

<style scoped lang="scss">
.cta-stoerer {
  position: relative;
  color: var(--color-white);
  background-color: var(--cta-background-color);
  width: 100%;
  max-width: 10rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
  transition: scale 200ms;

  @media (--lg) {
    max-width: 15rem;
    hyphens: none;
  }

  @media (hover: hover) and (pointer: fine) {
    @media (--lg) {
      &:hover {
        scale: 1.1;
      }

      &:hover::before {
        transform: scale(1);
        background-color: var(--cta-background-color-hover);
      }

      &:hover .link {
        transform: rotate(-8deg);
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--cta-background-color);
    border-radius: inherit;
    transform: scale(0);
    transition:
      transform 300ms ease-out,
      background-color 300ms;
    z-index: 0;
  }

  > .link {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25% var(--spacing-3xs) 15% var(--spacing-3xs);
    transition: transform 200ms;

    @media (--lg) {
      gap: var(--spacing-3xs);
    }

    > .icon {
      transform: rotate(-45deg);
      width: 1rem;
      aspect-ratio: 1 / 1;

      @media (--lg) {
        transform: rotate(-15deg);
        width: 2rem;
      }
    }
  }
}
</style>
