<template>
  <header
    class="site-header"
    :class="[
      { '-scrolled-down': hasScrolled && scrolledDown && !isMenuOpen },
      { '-open': isMenuOpen },
      { '-scrolled-up': hasScrolled && !scrolledDown && !isNearHeaderMedia },
      { '-near-header-media': isNearHeaderMedia },
    ]"
  >
    <div class="inner" :class="{ '-open': isMenuOpen }">
      <HamburgerMenu :class="{ '-open': isMenuOpen }" class="menutoggle" @click="toggleMenu()" />
      <HomeLink class="fgz-home-link" />
      <MainNavigation :key="locale" class="mainnavigation _hidden-mobile" />
      <MainNavigationSmall :key="locale" :class="{ '-open': isMenuOpen }" class="mainnavigation _hidden-desktop" />
      <LangSwitch :key="locale" class="langswitch" :class="{ '_hidden-mobile': !isMenuOpen }" />
    </div>
  </header>
</template>

<script setup lang="ts">
import LangSwitch from '~/components/site-header/LangSwitch.vue';
import MainNavigation from '~/components/site-header/MainNavigation.vue';
import HomeLink from '~/components/site-header/components/HomeLink.vue';
import HamburgerMenu from '~/components/site-header/components/HamburgerMenu.vue';
import MainNavigationSmall from '~/components/site-header/MainNavigationSmall.vue';

const route = useRoute();

const { locale } = useI18n();

const isMenuOpen = ref<boolean>(false);

const isNearHeaderMedia = ref<boolean>(false);
const hasScrolled = ref(false);

const { y: scrollY } = useWindowScroll();
const scrolledDown = ref<boolean>(false);
let lastScrollY = 0;

function toggleMenu(open = !isMenuOpen.value) {
  isMenuOpen.value = open;

  toggleLock(isMenuOpen.value);
}

function toggleLock(lock: boolean) {
  if (lock) {
    document.body.style.overflowY = 'clip';
  } else {
    document.body.style.overflowY = 'unset';
  }
}

function initHeaderMediaObserver() {
  const headerMedia = document.querySelector('.header-media');

  if (headerMedia) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          isNearHeaderMedia.value = entry.isIntersecting;
        });
      },
      {
        rootMargin: '100px 0px 0px 0px',
        threshold: 0.5,
      },
    );
    observer.observe(headerMedia);
  }
}

onMounted(function () {
  initHeaderMediaObserver();
});

watch(scrollY, (newY) => {
  // watch if the user has scrolled down and is still scrolling down
  scrolledDown.value = newY > 50 && newY > lastScrollY;
  lastScrollY = newY;

  hasScrolled.value = true;
});

watch(
  () => route.path,
  function () {
    toggleMenu(false);
    setTimeout(() => {
      initHeaderMediaObserver();
    }, 300);
  },
);
</script>

<style scoped lang="scss">
.site-header {
  color: var(--base-color-brand);
  background-color: var(--base-color-background);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-header);
  width: 100%;
  transform: translate(0, 0);
  transition:
    transform 200ms,
    background-color 300ms;

  &.-open {
    @media (--xs), (--sm) {
      background: var(--color-main-blue-light);
      height: 100%;
    }
  }

  &.-scrolled-down {
    transform: translate(0, -100%);
  }

  > .inner {
    --height-nav-small: 5.5rem;

    margin: auto;
    padding: var(--spacing-sm) var(--spacing-2xl);
    height: 9.5rem;
    display: flex;
    align-items: center;
    gap: var(--spacing-2xs);
    max-width: var(--site-max-width);

    @media (--xs), (--sm) {
      padding: var(--spacing-2xs);
      position: fixed;
      width: 100%;
      height: var(--height-nav-small);
      background: var(--base-color-background);
      overflow-y: auto;

      &.-open {
        background: var(--color-main-blue-light);
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        gap: var(--spacing-md);
      }
    }

    @media (--md) {
      padding: var(--spacing-sm) var(--spacing-2xs);
      gap: var(--spacing-3xs);
    }

    > .menutoggle {
      top: calc(var(--height-nav-small) / 2);
      position: absolute;
      right: 1.5rem;
      transform: translateY(-50%);
      z-index: var(--z-header);

      @media (--xs), (--sm) {
        display: flex;
      }

      @media (--md), (--lg) {
        display: none;
      }
    }

    > .langswitch {
      @media (--xs), (--sm) {
        margin-top: auto;
      }
    }
  }
}

// special style for navigation on header media
.app:has(.header-media) .site-header {
  background-color: transparent;
  color: var(--color-white);

  &.-open {
    @media (--xs), (--sm) {
      background: var(--color-main-blue-light);
    }

    :deep(.logo.-blue) {
      display: block;
    }

    :deep(.logo.-white) {
      display: none;
    }
  }

  &.-scrolled-up {
    background: var(--base-color-background);

    > .inner {
      color: var(--color-main-blue);
      background: var(--base-color-background);

      &.-open {
        background: var(--color-main-blue-light);
      }
    }

    :deep(.icon-arrow-down > path) {
      stroke: var(--color-main-blue);
    }

    :deep(.logo.-blue) {
      display: block;
    }

    :deep(.logo.-white) {
      display: none;
    }
  }

  &.-scrolled-down {
    @media (--xs), (--sm) {
      > .inner {
        color: var(--color-main-blue);
        background: var(--base-color-background);

        &.-open {
          background: var(--color-main-blue-light);
        }
      }

      :deep(.icon-arrow-down > path) {
        stroke: var(--color-main-blue);
      }

      :deep(.logo.-blue) {
        display: block;
      }

      :deep(.logo.-white) {
        display: none;
      }
    }
  }

  &.-scrolled-down.-near-header-media {
    @media (--md), (--lg) {
      transform: translate(0, 0);
    }
  }

  > .inner {
    @media (--xs), (--sm) {
      background-color: transparent;
      color: var(--color-white);
    }

    &:hover {
      @media (--md), (--lg) {
        background-color: var(--base-color-background);
        color: var(--color-main-blue);

        :deep(.icon-arrow-down > path) {
          stroke: var(--color-main-blue);
        }

        :deep(.logo.-blue) {
          display: block;
        }

        :deep(.logo.-white) {
          display: none;
        }
      }
    }

    > .main-navigation-small,
    > .hamburger-menu.-open,
    > .langswitch {
      color: var(--color-main-blue);
    }
  }

  :deep(.icon-arrow-down > path) {
    stroke: var(--color-white);
  }

  :deep(.logo.-blue) {
    display: none;
  }

  :deep(.logo.-white) {
    display: block;
  }
}
</style>
