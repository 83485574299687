<template>
  <header ref="headerMediaElement" class="header-media">
    <div class="mediawrapper">
      <Image
        v-if="data.media && data.media.responsiveImage"
        class="headerimage"
        :data="data.media.responsiveImage"
        object-fit="cover"
      />
      <video
        v-else-if="data.media && data.media.video?.mp4Url"
        class="headervideo"
        loop
        autoplay
        muted
        playsinline
        :aria-label="data.media?.video.alt as string"
      >
        <source :src="data.media.video.mp4Url" type="video/mp4" />
        Sorry, it appears your system does not support video playback.
      </video>
      <Stairs class="stairs" />
      <div class="introsteps" aria-hidden="true">
        <div class="step -top" />
        <div class="step -middle" />
        <div class="step -bottom" />
      </div>
    </div>
    <div ref="gridContainerElement" class="grid-container">
      <CtaStoerer v-if="data.stoerer" :data="data?.stoerer" class="stoerer" />
      <div class="inner">
        <h1 v-if="data.title" class="title">
          {{ data.title }}
        </h1>
        <StructuredText v-if="data.lead" class="structured-text text lead" :data="data.lead" />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { Image, StructuredText } from 'vue-datocms';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import type { HeaderMediumFragment } from '#gql';
import Stairs from '~/components/header-modules/svgs/Stairs.vue';
import CtaStoerer from '~/components/content-modules/CtaStoerer.vue';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

defineProps<{
  data: HeaderMediumFragment;
}>();

const headerMediaElement = ref<null | HTMLElement>(null);

onMounted(function () {
  if (headerMediaElement.value?.querySelector('.stoerer')) {
    setTimeout(function () {
      history.scrollRestoration = 'manual'; // prevent automatic page location restoration
      window.scrollTo(0, 0); // reset scroll position
    }, 1);

    scrollAnimation(headerMediaElement.value);
  }
});

function scrollAnimation(el: HTMLElement) {
  const mm = gsap.matchMedia(); // create match media queries
  const isDesktop = '(min-width: 1200px)';

  mm.add(isDesktop, () => {
    // Pin Header during whole Introanimation
    ScrollTrigger.create({
      trigger: el,
      start: 'top top',
      end: '200px top',
      pin: true,
      invalidateOnRefresh: true,
    });

    // 1. Appear In Animation
    const tlAppearIn = gsap.timeline();

    tlAppearIn
      .to('.header-media > .mediawrapper > .headervideo ', { scale: 1, duration: 1, ease: 'power1.inOut' })
      .from(
        '.header-media > .mediawrapper > .introsteps',
        {
          autoAlpha: 0,
          xPercent: 100,
          duration: 1,
          ease: 'power1.inOut',
        },
        '<',
      )
      .from(
        '.header-media > .grid-container',
        {
          autoAlpha: 0,
          xPercent: 100,
          duration: 1,
          ease: 'power1.inOut',
        },
        '>-0.7',
      )
      .from(
        '.header-media > .grid-container > .stoerer',
        {
          scale: 0,
          duration: 0.4,
        },
        '>-0.1',
      );

    // 2. Scrollanimation
    const tlScroll = gsap.timeline({
      paused: true,
      scrollTrigger: {
        start: '100px top',
        end: '+=500',
        toggleActions: 'play complete complete pause',
        invalidateOnRefresh: true,
      },
    });

    tlScroll
      .to('.header-media > .mediawrapper > .introsteps > .step', {
        duration: 0.7,
        width: '110%',
      })
      .fromTo(
        '.header-media > .grid-container > .inner',
        {
          xPercent: 45,
        },
        {
          xPercent: 0,
        },
        '<',
      );
  });
}
</script>

<style scoped lang="scss">
// special style for introanimation
.header-media:has(.stoerer) {
  @media (--lg) {
    --height-introsteps: 33vh;

    min-height: calc(100vh - 2rem);

    .mediawrapper {
      min-height: calc(100vh - 2rem);
      padding-bottom: 1px; // workaround subpixel rendering issue

      > .headervideo {
        scale: 1.3;
      }
    }

    .stairs {
      display: none;
    }

    .grid-container {
      position: relative;
      z-index: 1;
      margin-top: calc(var(--height-introsteps) * -1);
      min-height: var(--height-introsteps);

      > .inner > .title {
        hyphens: none;
      }
    }

    .introsteps {
      height: var(--height-introsteps);

      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 1;

      > .step {
        position: relative;
        background: var(--color-white);
        width: 60%;
        right: -1px;

        &::before {
          @include pseudo();

          background-color: var(--color-white);
          height: 100%;
          left: 2px; // workaround subpixel rendering issue
          transform: translateX(-100%);
        }

        &.-top {
          position: absolute;
          top: 3px; // 3px avoid subpixel rendering issue
          height: calc(100% / 3);
        }

        &.-middle {
          position: absolute;
          bottom: calc(100% / 3 - 2px); // -2px to avoid subpixel rendering issue
          height: calc(100% / 3);

          &::before {
            aspect-ratio: 1 / 1;
          }
        }

        &.-bottom {
          position: absolute;
          bottom: -1px;
          height: calc(100% / 3);

          &::before {
            aspect-ratio: 2 / 1;
          }
        }
      }
    }

    .grid-container,
    .introsteps {
      visibility: hidden;
    }
  }
}

.header-media {
  position: relative;
  width: 100%;
  max-width: var(--site-max-width);
  overflow: hidden;

  > .grid-container {
    @include grid-layout();

    & {
      position: relative;
      padding: var(--site-inner-padding);
      width: 100%;
    }

    @media (--xs), (--sm), (--md) {
      background-color: var(--color-white);
    }

    > .stoerer {
      position: relative;
      z-index: calc(var(--z-header) - 1);
      margin-top: -100%;
      transform: translateY(50%);
      grid-row: 1 / 2;
      grid-column: 1 / -1;
      justify-self: end;
      align-self: end;
    }

    > .inner {
      display: grid;
      gap: var(--spacing-xs);
      padding: var(--spacing-4xl) 0;
      grid-row: 2 / 3;
      max-width: 900px;

      @media (--xs), (--sm) {
        padding: var(--spacing-2xl) 0 var(--spacing-sm) 0;
      }

      @media (--xs) {
        gap: var(--spacing-2xs);
      }

      @media (--xs) {
        grid-column: 1 / -1;
      }

      @media (--sm) {
        grid-column: 2 / 24;
      }

      @media (--md), (--lg) {
        grid-column: 5 / 21;
      }
    }
  }

  > .mediawrapper {
    position: relative;
    width: 100%;
    height: 80svh;
    overflow: hidden;

    .headerimage,
    .headervideo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.8) 100%);
      z-index: 1;
      inset: 0;
    }

    > .stairs {
      position: absolute;
      bottom: -1px;
      right: -1px;

      @media (--xs) {
        display: none;
      }

      @media (--sm), (--md), (--lg) {
        aspect-ratio: 1 / 1;
        height: 50%;
      }
    }
  }
}
</style>
